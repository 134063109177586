import request from '@/utils/request'


export function getGoods(params) {
    return request({
        method:'get',
        url:'/api/amazon/asin/stockListPage',
        params
    })
}

// 查询分页
export function ListPage(params) {
    return request({
        url:'/api/ad/groups/listPage',
        method:'get',
        params
    });
}

// 查询初始
export function pageInit(id) {
    return request({
        url:'/api/ad/groups/pageInit',
        method:'get',
        headers:{
            'profileId':id
        }
    });
}

// 新增初始化
export function addInit(id) {
    return request({
        url:'/api/ad/groups/addInit',
        method:'get',
        headers:{
            'profileId':id
        }
    });
}
//新增
export function addGroup(data,profileId) {
    return request({
        url:'/api/ad/groups',
        method:'post',
        data,
        headers:{
            'profileId':profileId
        }
    });
}

// 根据ASIN或取商品
export function getAsinByAsins(data) {
    return request({
        url:'/api/ad/groups/getAsinByAsins',
        method:'post',
        data
    });
}

export function getKeywordsByAsin(data,profileId) {
    return request({
        url:`/api/ad/keyword/listSuggestedKeywordsByAsin/${data}`,
        method:'get',
        headers:{
            "profileId":profileId
        }
    });
}


// 根据ASIN获取建议分类投放
export function getCategories(params,profileId) {
    return request({
        url:`/api/ad/target/getCategories/${params}`,
        method:'get',
        headers:{
            "profileId":profileId
        }
    });
}
// 获取已建议
export function getTargetProduct(params,profileId) {
    return request({
        url:`/api/ad/target/getTargetProductRecommendations/${params}`,
        method:'get',
        headers:{
            "profileId":profileId
        }
    });
}

// 搜索获取品牌

export function getTargetsBrands(params,profileId) {
    return request({
        url:'/api/ad/target/getTargetsBrands',
        method:'get',
        params,
        headers:{
            "profileId":profileId
        }
    });
}

// 批量操作
export function batchUpdate(data,profileId) {
    return request({
        url:'/api/ad/groups/batchUpdate',
        method:'put',
        data,
        headers:{
            'profileId':profileId
        }
    });
}

// 更新初始化
export function updateInit(id) {
    return request({
        url:`/api/ad/groups/updateInit/${id}`,
        method:'get'
    });
}

//更新广告组
export function changeGroup(data,profileId) {
    return request({
        url:'/api/ad/groups',
        method:'put',
        data,
        headers:{
            "profileId":profileId
        }
    });
}

// 查询否定投放===右侧回显
export function listNegativeTarget(params) {
    return request({
        url:'/api/ad/target/listNegativeTarget',
        method:'get',
        params
    });
}
export function listNegativeKeywords(params) {
    return request({
        url:'/api/ad/keyword/listNegativeKeywords',
        method:'get',
        params
    });
}

// 添加否定关键词
export function addNegativeKeyword(data,profileId) {
    return request({
        url:'/api/ad/keyword/addNegativeKeyword',
        method:'post',
        data,
        headers:{
            "profileId":profileId
        }
    });
}
// 添加否定商品
export function addNeTargets(data,profileId) {
    return request({
        url:'/api/ad/target/addNeTargets',
        method:'post',
        data,
        headers:{
            "profileId":profileId
        }
    });
}


// 获取建议竞价
export function getGroupSuggestBid(id,profileId) {
    return request({
        url:`/api/ad/groups/getGroupSuggestBid/${id}`,
        method:'get',
        headers:{
            "profileId":profileId
        }
    });
}


// 关注与取消关注
export function focusOn(id) {
    return request({
        url:'/api/cpc/focus/add',
        method:'get',
        params:{
            adGroupId:id
        }
    });
}
export function focusOff(id) {
    return request({
        url:'/api/cpc/focus/cancel',
        method:'get',
        params:{
            adGroupId:id
        }
    });
}

// 修改否定投放状态

export function archivedNeTarget(data,profileId) {
    return request({
        url:'/api/ad/target/archivedNeTarget',
        method:'put',
        data,
        headers:{
            "profileId":profileId
        }
    });
}
// 修改否定关键词状态
export function updateNeKeyword(data,profileId) {
    return request({
        url:'/api/ad/keyword/updateNeKeyword',
        method:'put',
        data,
        headers:{
          "profileId":profileId
        }
    });
}

// 细化初始化
export function groupDetailInit(params,profileId) {
    return request({
        url:'/api/ad/groups/groupDetailInit',
        method:'get',
        params,
        headers:{
            "profileId":profileId
        }
    });
}

export function updateBid(data,profileId) {
    return request({
        url:'/api/AmzAdCampaigns/updateBid',
        data,
        method:'put',
        headers:{
            "profileId":profileId
        }
    });
}

// 查询商品初始化
export function goodsPageInit() {
    return request({
        method:'get',
        url:'/api/amazon/asin/pageInit',
    });
}

// 表现明细查询
export function listByGroup(params) {
   return request({
      url: "/api/ad/groups/listByGroup",
      params,
      mrthod: "get" 
   })
}


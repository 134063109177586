<template>
  <div style="width:100%;height:100%;padding:0 15px">
        <p style="color:#999999;font-size:14px;padding-left:10px;margin-bottom:10px">{{info}}</p>
        <TimeQuantum 
        style="margin:0 10px"
        @selectFinish="topTime"
        @sectionFinish="sectionSure"
        section="近7天"></TimeQuantum>
        <el-button-group style="margin-top:2px">
            <el-button type="primary" @click="type=true">图形</el-button>
            <el-button type="primary" @click="type=false">表格</el-button>
        </el-button-group>
        <div v-show="type" ref="infoBox" style="width:100%;height:calc(100% - 80px);margin-top:10px">
        </div>
        <el-table
            :data="list"
            v-show="!type"
            height="calc(100% - 80px)"
            header-cell-style="background:#F5F7FA"
            cell-style="font-size:14px"
            style="width:100%;margin-top:10px;overflow:auto">
            <el-table-column
                align="center"
                prop="date"
                label="日期" >
            </el-table-column>
            <el-table-column
                :prop="item.prop"
                :label="item.name"
                width="width"
                v-for="item in legendList"
                :key="item.prop">
                <template slot-scope="scope">
                    <p>
                        <span v-if="item.currency">{{currency}}</span>
                        <span>{{scope.row[item.prop]}}</span>
                        <span v-if="item.persent">%</span>
                    </p>
                </template>
            </el-table-column>
        </el-table>
  </div>
</template>

<script>
import TimeQuantum from '@/components/TimeQuantum.vue'
export default {
    components:{
        TimeQuantum
    },
    props:{
        currency:{
            default:'',
            type:String
        },
        list:{
            default:[],
            type:Array
        },
        legendList:{
            default:[],
            type:Array
        },
        info:{
            type:String
        }
    },
    mounted(){
        this.tableData=this.list
        this.getchart()
        console.log(this.list,this.legendList);
    },
    updated(){
        console.log(this.list)
        // this.tableData=[]
        const dom = this.$echarts.init(this.$refs.infoBox)
        dom.dispose()
        this.tableData=this.list
        console.log(this.tableData);
        this.getchart()
    },
    data(){
        return {
            type:true,
            num:1,
            tableData:[],
        };
    },
    methods:{
        topTime(val){
            if(this.num==1) return
            this.$emit('analysisClick',val)
            // this.getchart()
        },
        sectionSure(val){
            if(this.num==1) return
            this.$emit('analysisClick',val)
        },
        getchart(){
            this.num++
            const dom = this.$echarts.init(this.$refs.infoBox)
        console.log(this.tableData);

            let option = {
                // title: {
                //     text: "广告表现",
                // },
                tooltip: {
                    trigger: "axis",
                },
                legend:{
                    data:this.legendList.map(i=> i.name),
                    x: "right",
                    y: "center",
                    orient: "vertical",
                    icon: "circle",
                },
                grid: {
                    left: "2%",
                    right: "7%",
                    bottom: "4%",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: this.tableData.map(item=> item.date)
                },
                yAxis: {
                    type: "value",
                },
                series:this.legendList.map(item=>{
                    return {
                        name:item.name,
                        smooth:true,
                        type:'line',
                        data:this.tableData.map(i=> i[item.prop]),
                        bargap:'0%',
                    }
                }),
            }
            dom.setOption(option);
        }
    }
}
</script>

<style>

</style>
<template>
  <div style="display: inline-block">
    <div class="box">
      <!-- <el-select clearable @clear="clear" v-model="quantum" placeholder="选择日期范围"> -->
      <el-select
        :clearable="clearble"
        @clear="clear"
        v-model="quantum"
        placeholder="选择日期范围"
      >
        <el-option
          :label="item"
          :value="item"
          v-for="(item, index) in date"
          :key="item"
          @click.native="select(item, index)"
        ></el-option>
      </el-select>
      <el-date-picker
        v-model="value1"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        format="yyyy-MM-dd"
        value-format="yyyy-MM-dd"
        @change="sure"
        :clearable="false"
        :maxTime="value1[1]"
        :picker-options="pickerOptions"
      >
      </el-date-picker>
    </div>
  </div>
</template>

<script>
export default {
  props: ["beginTime", "endTime", "section", "clearble"],
  data() {
    return {
      date: [
        "今天",
        "昨天",
        "本周",
        "本月",
        "上月",
        "近7天",
        "近15天",
        "近30天",
        "自定义",
      ],
      quantum: "近7天",
      value1: "",
      // pickerOptions:{
      //     disabledDate(time){
      //         return time.getTime() > new Date().getTime() - 8.64e6 ;
      //     }
      // },
      pickerOptions: {
        onPick: ({ maxDate,minDate }) => {
          this.choiceDate = minDate.getTime();
          if (maxDate) {
            this.choiceDate = "";
          }
        },

        disabledDate: (time) => {
            
          if (this.choiceDate !== '') {
            const one = 30 * 24 * 3600 * 1000;
            const minTime = this.choiceDate - one;
            const maxTime = this.choiceDate + one;
            return (time.getTime() < minTime || time.getTime() > new Date().getTime() - 8.64e6 ) || (time.getTime() > maxTime || time.getTime() > new Date().getTime() - 8.64e6);
          }
           return  time.getTime() > new Date().getTime() - 8.64e6 ;
        },
      },
      choiceDate:'',
      ind: "",
      flag: true,
    };
  },
  watch: {
    value1(a, b) {},
  },
  mounted() {
    if (!this.section) {
      this.quantum = this.section;
      this.select(this.section, 5);
      return;
    }
    this.date.forEach((item, index) => {
      if (item == this.section) {
        this.quantum = this.section;
        this.select(this.section, index);
      }
    });
  },
  methods: {
    clear() {
      this.value1 = ["", ""];
      this.$emit("selectFinish", { value: this.value1, index: this.ind });
    },
    select(date, index) {
      this.ind = index;
      const current = new Date().getTime();
      if (date == "昨天") {
        // console.log(new Date(parseInt(current-24*60*60*1000)).toLocaleString().replace(/:\d{1,2}$/,' '));
        this.value1 = [
          this.fmtDate(current - 24 * 60 * 60 * 1000),
          this.fmtDate(current - 24 * 60 * 60 * 1000),
        ];
      } else if (date == "本周") {
        let str = "7123456".charAt(new Date().getDay());
        this.value1 = [
          this.fmtDate(current - (str - 1) * 24 * 60 * 60 * 1000),
          this.fmtDate(current),
        ];
      } else if (date == "本月") {
        let day = this.fmtDate(current).substring(
          this.fmtDate(current).lastIndexOf("-") + 1
        );
        this.value1 = [
          this.fmtDate(current - (day - 1) * 24 * 60 * 60 * 1000),
          this.fmtDate(current),
        ];
      } else if (date == "近7天") {
        this.value1 = [
          this.fmtDate(current - 7 * 24 * 60 * 60 * 1000),
          this.fmtDate(current),
        ];
      } else if (date == "近15天") {
        this.value1 = [
          this.fmtDate(current - 15 * 24 * 60 * 60 * 1000),
          this.fmtDate(current),
        ];
      } else if (date == "近30天") {
        this.value1 = [
          this.fmtDate(current - 30 * 24 * 60 * 60 * 1000),
          this.fmtDate(current),
        ];
      } else if (date == "上月") {
        const oDate = new Date();
        var year = oDate.getFullYear();
        var month = oDate.getMonth();
        if (month == 0) {
          year--;
          this.value1 = [new Date(year, 11, 1), new Date(year, 11, 31)];
        } else {
          this.value1 = [
            this.fmtDate(new Date(year, month - 1, 1).getTime()),
            this.fmtDate(new Date(year, month, 0).getTime()),
          ];
        }
      } else if (date == "自定义") {
        // this.value1=[this.fmtDate(current),this.fmtDate(current)]
      } else if (date == "今天") {
        this.value1 = [this.fmtDate(current), this.fmtDate(current)];
      }
      this.$emit("selectFinish", {
        value: this.value1,
        index: this.ind,
        dateTypeName: this.quantum,
      });
      // this.$emit("sectionFinish", {
      //   value: this.value1,
      //   index: this.ind,
      //   dateTypeName: this.quantum,
      // });
    },
    // 根据时间戳获取格式化时间
    fmtDate(time) {
      var date = new Date(time);
      var y = 1900 + date.getYear();
      var m = "0" + (date.getMonth() + 1);
      var d = "0" + date.getDate();
      return (
        y +
        "-" +
        m.substring(m.length - 2, m.length) +
        "-" +
        d.substring(d.length - 2, d.length)
      );
    },
    sure() {
      this.ind = this.date.length - 1;
      this.quantum = "自定义";
      this.$emit("selectFinish", {
        value: this.value1,
        index: this.ind,
        dateTypeName: this.quantum,
      });
      this.$emit("sectionFinish", {
        value: this.value1,
        index: this.ind,
        dateTypeName: this.quantum,
      });
    },
  },
};
</script>

<style scoped lang='scss'>
.box {
  display: flex;
}
::v-deep .el-input {
  // background: black !important;
  width: 124px;
  border: 0;
  input {
    border-radius: 4px 0 0 4px;
    border-right: 0;
    text-align: center;
  }
}
::v-deep .el-date-editor {
  border-radius: 0 4px 4px 0;
  width: 260px;
}
</style>